import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { HeadProps } from '~src/components/Head'
import { HeaderPlaceHolder } from '~src/components/HeaderPlaceHolder'
import { Footer } from '~src/components/Footer'
import { sectionInlineSize, smBreakPoint, xsBreakPoint } from '~src/common/cssTips'
import { Main } from '~src/units/Main'
import { Article } from '~src/units/Article'
import bg from '~src/assets/image/bg-privacy.svg'

const Section = styled.section`
  ${sectionInlineSize}
  padding-top: 80px;
  margin-top: -15vh;

  @media screen and (max-width: ${smBreakPoint}) {
    padding-top: 60px;
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    padding-top: 40px;
  }
`
const BackgroundWrapper = styled.div`
  position: relative;
  margin-top: 300px;
`

const Background = styled.img.attrs({ src: bg })`
  position: absolute;
  bottom: 20px;
  right: -20px;
  max-width: 100%;
`

export interface PrivacyProps extends HeadProps {
}

const PrivacyPage: React.FC<PrivacyProps> = ({ data }) => (
  <Main>
    <HeaderPlaceHolder />
    <Section>
      <Article dangerouslySetInnerHTML={{ __html: data.markdownRemark!.html }} />
      <BackgroundWrapper>
        <Background />
      </BackgroundWrapper>
    </Section>
    <Footer />
  </Main>
)

export default PrivacyPage

export const pageQuery = graphql`
  query TermsMDQuery {
    markdownRemark(frontmatter: { slug: { eq: "Terms" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
